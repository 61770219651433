import React from "react";
import { useHistory } from "react-router-dom";
import logo from "../../images/logo_extended.png";

export function Logo({ className, onClick }) {
  const history = useHistory();

  const click = (event) => {
    if (onClick) {
      onClick(event);
    } else {
      history.push("/");
    }
  };

  return (
    <div className={`${className ?? ""} logo d-flex align-items-baseline`}>
      <img onClick={click} src={logo} alt="CloudStrat Logo" />
    </div>
  );
}
