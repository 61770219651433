import { DownloadContext } from "App";
import { ColumnLayout } from "Common/ColumnLayout";
import HR from "Common/HR";
import { KeyValueEditor } from "Common/KeyValueEditor";
import { UserContext } from "Common/UserContext";
import { UserItem } from "Common/UserItem";
import { DateTime, RelativeDateTime } from "Datetime";
import firebase from "firebase/compat/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Dropdown, Form, ListGroup, Modal, Spinner } from "react-bootstrap";
import { BiRefresh } from "react-icons/bi";
import { BsTrash, BsX } from "react-icons/bs";
import { CgAdd, CgCopy, CgTrash } from "react-icons/cg";
import { IoMdCheckmark, IoMdWarning } from "react-icons/io";

export function JobsDashboardPage(props) {
    const { jobId, setSelectedItem } = props;

    const [addJobDialog, setAddJobDialog] = useState(false);

    const clearJobs = () => {
        httpsCallable(getFunctions(), "jobs-clearJobs")();
    }

    return <>
        {addJobDialog && <AddJobDialog close={() => setAddJobDialog(false)} />}
        <ColumnLayout
            widths={[500, 0]}
            content={[
                <Card className="h-100">
                    <Card.Header className="d-flex justify-content-between align-items-center">
                        <div style={{ fontWeight: "500" }}>Jobs</div>
                        <div>
                            <BsTrash onClick={() => clearJobs()} className="pointer me-2" />
                            <CgAdd onClick={() => setAddJobDialog(true)} className="pointer" />
                        </div>
                    </Card.Header>
                    <Card.Body style={{ height: "0" }}>
                        <JobList id={jobId} selectedId={jobId} setSelectedItem={setSelectedItem} />
                    </Card.Body>
                </Card>,
                jobId ? <JobPage id={jobId} setSelectedItem={setSelectedItem} /> : <></>,
            ]}
        />
    </>;
}

function JobList(props) {
    const downloads = useContext(DownloadContext);

    const { selectedId, setSelectedItem } = props;

    const items = Object.values(downloads ?? {}).sort((a, b) => b.createdAt - a.createdAt);

    return (
        <>
            <ListGroup className="h-100 overflow-auto">
                {items.map((x) => (
                    <JobItem key={x.id} id={x.id} selected={x.id === selectedId} setSelectedItem={setSelectedItem} />
                ))}
            </ListGroup>
        </>
    );
}

function JobItem(props) {
    const { id, selected, setSelectedItem, state, compact } = props;

    const downloads = useContext(DownloadContext);
    const downloadJob = downloads && downloads[id];

    if (compact) {
        return <div onClick={() => setSelectedItem(id)} className="d-flex align-items-center me-2 my-1" style={{
            padding: "0.25em 0.5em",
            borderRadius: "0.5em",
            background: "#eee",
            cursor: "pointer",
            fontWeight: "600",
        }}>
            <JobState state={state ?? downloadJob?.state} className="d-flex align-items-center me-2" />
            <div className="d-flex align-items-center small">{id}</div>
        </div>;
    }

    return (
        <ListGroup.Item variant={selected && "secondary"} action={!!setSelectedItem} onClick={() => setSelectedItem(id)}>
            <div className="d-flex align-items-center">
                <JobState id={id} className="me-3" />
                <div className="d-flex flex-column flex-grow-1" style={{ width: "0" }}>
                    <div className="d-flex justify-content-between">
                        <div style={{ fontWeight: "500" }}>{downloadJob?.runner}</div>
                        <BsTrash className="pointer" style={{position: "relative", right: "-0.25em"}} onClick={() => {
                            firebase.database().ref(`jobs/${id}`).set({});
                        }} />
                    </div>
                    <code title={downloadJob?.canonical_name} style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden"
                    }}>{downloadJob?.canonical_name}</code>
                    {
                        <small>
                            {downloadJob?.updatedAt ? (
                                <>
                                    Updated <RelativeDateTime epochSeconds={downloadJob?.updatedAt/1000} />
                                </>
                            ) : (
                                <>
                                    Created <RelativeDateTime epochSeconds={downloadJob?.createdAt/1000} />
                                </>
                            )}
                        </small>
                    }
                </div>
            </div>
        </ListGroup.Item>
    );
}

function JobPage(props) {
    const { id, setSelectedItem } = props;
    const user = useContext(UserContext);
    const downloads = useContext(DownloadContext);
    const downloadJob = downloads && downloads[id];
    const hasParameters = Object.keys(downloadJob?.params ?? {}).length > 0;

    if (!downloadJob) {
        return <></>;
    }

    return <Card className="h-100 overflow-hidden d-flex flex-column">
        <Card.Header>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <JobState id={id} className="d-flex align-items-center me-3" />
                    <div className="d-flex align-items-center" style={{ fontWeight: "500" }}>{downloadJob?.runner}</div>
                </div>
                <div className="d-flex align-items-center">
                    <BiRefresh className="pointer me-1" onClick={() => {
                        const { id } = downloadJob;

                        httpsCallable(firebase.functions(), "jobs-retryJob")({ jobId: id });
                    }} />
                    <CgCopy className="pointer me-1" onClick={() => {
                        const { id, output, error, state, log, ...definition } = downloadJob;

                        firebase.database().ref("jobs").push({
                            ...definition,
                            createdAt: new Date().getTime() / 1000,
                            owner: user.uid,
                        }).then((result) => {
                            setSelectedItem(result.id);
                        }).catch(console.error);
                    }} />
                    <CgTrash className="pointer" onClick={() => {
                        firebase.database().ref("jobs").child(downloadJob?.id).set({});
                        setSelectedItem(null);
                    }} />
                </div>
            </div>
        </Card.Header>
        <Card.Body className="d-flex flex-column">
            <section className="d-flex flex-column">
                <UserItem id={downloadJob?.owner} className="mb-2" />
                <JobPageItem content={
                    [
                        <h6>Created on</h6>,
                        <DateTime epochSeconds={downloadJob?.createdAt/1000} />,
                        <>(<RelativeDateTime epochSeconds={downloadJob?.createdAt/1000} />)</>,
                    ]
                } />
                <JobPageItem content={
                    [
                        <h6>Updated at </h6>,
                        <DateTime epochSeconds={downloadJob?.updatedAt/100} />,
                        <>(<RelativeDateTime epochSeconds={downloadJob?.updatedAt/100} />)</>
                    ]
                } />
                <JobPageItem content={
                    [
                        <h6>Last status </h6>,
                        <div>{downloadJob?.status ?? "(None)"}</div>
                    ]
                } />
            </section>
            <HR className="my-3" />
            <div className="d-flex flex-column flex-grow-1" style={{overflowX: "hidden", overflowY: "auto", margin: "-1em", padding: "1em", height: "0"}}>
                {(hasParameters || downloadJob?.canonical_name) && <>
                    <section className="d-flex flex-column">
                        {downloadJob?.canonical_name && <>
                            <JobPageItem content={
                                [
                                    <h6>Canonical name </h6>,
                                    <code>{downloadJob?.canonical_name}</code>
                                ]
                            } className={hasParameters && "mb-2"} />
                        </>}
                        {hasParameters && <>
                            <h6>Parameters</h6>
                            {Object.entries(downloadJob?.params).map(([name, value]) => <JobParam name={name} value={value} />)}
                        </>}
                    </section>
                </>}
                {downloadJob?.inputs && <>
                    <h6 className="mt-2 mb-1">Inputs</h6>
                    <section className="d-flex flex-column">
                        <code><pre className="m-0">{JSON.stringify(downloadJob?.inputs, null, 2)}</pre></code>
                    </section>
                </>}
                {(downloadJob?.parent_id || downloadJob?.deps?.length > 0) && <HR className="my-3" />}
                <section className="d-flex flex-column">
                    {downloadJob?.parent_id && <JobPageItem content={
                        [
                            <h6>Required by</h6>,
                            <JobItem setSelectedItem={setSelectedItem} id={downloadJob?.parent_id} compact />
                        ]
                    } />}
                    {Object.keys(downloadJob?.deps ?? {}).length > 0 && <JobPageItem className="mt-3 mb-1" content={
                        [
                            <h6>Depends on</h6>,
                            <>{Object.keys(downloadJob?.deps ?? {}).length} jobs</>
                        ]
                    } />}
                    <div className="d-flex flex-wrap">
                        {Object.keys(downloadJob?.deps ?? []).map(x => <JobItem setSelectedItem={setSelectedItem} id={x} state={downloadJob.deps[x]} compact />)}
                    </div>
                </section>
                {downloadJob?.error && <>
                    <HR label="Error" className="my-3" />
                    <section>
                        <pre>
                            {downloadJob?.error}
                        </pre>
                    </section>
                </>}
                {downloadJob?.log && <>
                    <HR label="Logs" className="my-3" />
                    <pre className="overflow-auto">
                        {Object.values(downloadJob?.log ?? {}).map(x => <div>{x}</div>)}
                    </pre>
                </>}
                {downloadJob?.output && <>
                    <HR label="Output" className="my-3" />
                    <section className="d-flex flex-column flex-grow-1">
                        <pre className="m-0">{JSON.stringify(downloadJob?.output, null, 2)}</pre>
                    </section>
                </>}
            </div>
        </Card.Body>
    </Card>;
}

function JobPageItem(props) {
    const { content, className } = props;

    return <div className={`d-flex align-items-center ${className}`}>
        {content.map(x => <div className="me-3">{x}</div>)}
    </div>
}

function JobState(props) {
    const { id, state, className } = props;

    const downloads = useContext(DownloadContext);
    const downloadJob = downloads ? (downloads[id] ?? {}) : {};
    const state_ = state ?? downloadJob.state;

    const done = state_ === "success" || state_ === "error";
    const stale =
        !done &&
        new Date().getTime() / 1000.0 - downloadJob?.updatedAt >
        60 * 10;

    const icon = done ? state_ === "error" ? <BsX style={{ color: "red" }} /> : (
        <IoMdCheckmark style={{ color: "green" }} />
    ) : stale ? (
        <IoMdWarning style={{ color: "orange" }} />
    ) : (
        <Spinner size="sm" animation="border" />
    );

    return <div className={className}>{icon}</div>;
}

function JobParam(props) {
    const { name, value } = props;

    let value_ = Array.isArray(value) || typeof value === "object" ? JSON.stringify(value) : value;

    return <div className="d-flex align-items-baseline">
        <code>{name}</code>
        <div className="mx-3">=</div>
        <code>{name?.substr(0, 6) === "secret" ? "<secret>" : value_}</code>
    </div>;
}

function AddJobDialog(props) {
    const { close } = props;

    const [runner, setRunner] = useState();
    const [params, setParams] = useState([]);

    const [loading, setLoading] = useState(false);

    const user = useContext(UserContext);

    const onSubmit = () => {
        setLoading(true);
        let param = {};
        for (const [key, value] of params) {
            param[key] = value;
        }
        firebase.database().ref("jobs").push({
            runner,
            params: param,
            state: "pending",
            createdAt: new Date().getTime() / 1000,
            owner: user.uid
        }).then((result) => {
            setLoading(false);
            close();
        }).catch(console.error).finally(() => {
            setLoading(false);
        });
    };

    const presets = {
        "runners.refinitiv.RefinitivPriceHistory": {
            "username": "9035086",
            "secret_password": "CloudStrat1!",
            "ric": null,
            "end_date": new Date().toISOString().split("T")[0],
        },
        "runners.yahoo.YahooFinance": {
            "ticker": null,
            "end_date": new Date().toISOString().split("T")[0],
        },
        "runners.base.TestDelayRunner": {
            "name": "test",
            "delay": "20",
            "depth": "1",
            "num_children": "1"
        },
        "runners.copilot.GenerateBlockFromName": {
            "user": user.uid,
            "name": "Fourier Analysis",
        }
    };

    return <Modal show={true}>
        <Modal.Header onHide={close} closeButton>
            <Modal.Title>Add a new download job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3">
                    <Form.Label>Template</Form.Label>
                    <Dropdown className="w-100">
                        <Dropdown.Toggle variant="secondary" className="d-flex justify-content-between align-items-center w-100">
                            <div className="flex-grow-1" style={{ textAlign: "start" }}>{runner ?? "Choose Template"}</div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="w-100">
                            {Object.keys(presets).map(x => <Dropdown.Item onClick={() => {
                                setRunner(x);
                                setParams(Object.entries(presets[x]));
                            }}>{x}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Runner</Form.Label>
                    <Form.Control value={runner} onChange={(e) => setRunner(e.target.value)} />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Parameters</Form.Label>
                    <KeyValueEditor data={params} save={setParams} key={params} />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={onSubmit} disabled={loading}>
                {loading && <Spinner animation="border" size="sm" className="me-2" />}
                Add
            </Button>
        </Modal.Footer>
    </Modal>;
}