import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { BiCaretRight } from "react-icons/bi";

export function FirestoreTeamItem(props) {
  const { teamId, small, className, iconOnly, light, select, size } = props;
  const [team, setTeam] = useState();

  useEffect(() => {
    firebase
      .firestore()
      .collection("teams")
      .doc(teamId)
      .onSnapshot((snapshot) => {
        setTeam(snapshot.data());
      });
  }, [teamId]);

  return team ? <TeamItem team={team} small={small} light={light} select={select} className={className} iconOnly={iconOnly} size={size} /> : <></>;
}

export function TeamItem(props) {
  const [team, setTeam] = useState();

  useEffect(() => {
    if (props.id) {
      firebase
        .firestore()
        .collection("teams")
        .doc(props.id)
        .onSnapshot((snapshot) => {
          setTeam(snapshot.data());
        });
    } else {
      setTeam(props.team);
    }
  }, [props.id, props.team]);

  let label = props.team?.strategies?.length;
  if (label === 0) {
    label = "no strategies";
  } else if (label === 1) {
    label = "one strategy";
  } else {
    label += " strategies";
  }

  const image = (
    <img
      src={team?.photoURL}
      className={`team-logo-image ${props.iconOnly && props.select && "pointer"} ${props.menuItem && "me-4"} ${props.className}`}
      style={
        props.iconOnly
          ? {
              width: props.size ? props.size : props.menuItem ? "26px" : "22px",
              height: props.size ? props.size : props.menuItem ? "26px" : "22px",
              borderRadius: "2px",
              marginRight: !props.iconOnly && "5px",
              display: "inline",
            }
          : {
              marginRight: !props.menuItem && "0.25em",
              width: props.size ? props.size : props.menuItem && "26px",
              height: props.size ? props.size : props.menuItem && "26px",
            }
      }
      onClick={() => {
        if (props.iconOnly && props.select) {
          props.select();
        }
      }}
    />
  );
  const description = (
    <div className="d-flex flex-column justify-content-between flex-grow-1">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div
          className="team-name small"
          style={{
            visibility: props.collapsed && "hidden",
          }}
        >
          {team?.name}
        </div>
        {props.menuItem && props.active && !props.collapsed && <BiCaretRight style={{position: "relative", left: "3px"}} />}
      </div>
      <div className="team-strategies d-none small">{`Team sharing ${label}`}</div>
    </div>
  );

  if (props.iconOnly) {
    return image;
  }

  if (props.nameOnly) {
    return team?.name ? team?.name : "Team";
  }

  return (
    <ListGroup.Item
      key={props.id}
      className={
        "team-list-item w-100" + (props.small ? " small" : "") + ((props.menuItem || props.light) ? " menu-item" : "") + (props.active ? " highlighted" : "")
      }
      variant={props.menuItem ? "none" : "light"}
      onClick={() => {
        if (props.select) {
          props.select();
        }
      }}
      active={props.active}
      action={props.select}
    >
      <div className="w-100 d-flex flex-row justify-content-start align-items-center">
        {image}
        {description}
      </div>
    </ListGroup.Item>
  );
}
