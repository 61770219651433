import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { Logo } from "./Common/Logo";
import React, { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

export default function LandingPage() {
    const [email, setEmail] = useState();
    const [emailSent, setEmailSent] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState();
    const history = useHistory();

    if (isSignInWithEmailLink(getAuth(), window.location.href)) {
        var signInEmail = window.localStorage.getItem("emailForSignIn");
        if (!signInEmail) {
            signInEmail = window.prompt("Please provide your email for confirmation");
        }
        signInWithEmailLink(getAuth(), signInEmail, window.location.href)
            .then(() => {
                window.localStorage.removeItem("emailForSignIn");
                window.location = "/";
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const submit = () => {
        setLoading(true);
        sendSignInLinkToEmail(getAuth(), email, {
            url: "https://data.cloudstrat.com",
            handleCodeInApp: true,
        })
            .then(() => {
                window.localStorage.setItem("emailForSignIn", email);
                setError(null);
                setEmailSent(true);
                setLoading(false);
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log(errorCode, errorMessage);
                setLoading(false);
                setError(errorMessage);
            });
    }

    return <div className="w-100 h-100 d-flex justify-content-center">
        <div style={{ width: "250px" }} className="mt-5">
            <div className="logo">
                <div onClick={
                    (event) => {
                        history.push("/");
                    }
                }>
                    <div className="d-flex align-items-baseline mb-3 me-3" style={{ height: "45px" }}>
                        <Logo className="h-100 me-2" />
                        <span className="title" style={{ fontWeight: "400" }}>
                            Data
                        </span>
                    </div>
                </div>
            </div>
            {emailSent ? <div style={{ color: "white", textAlign: "center" }}>Please check your email and click on the link inside</div> :
                error ? (
                    <small style={{ color: "#faa" }}>
                        {error} Please try again.
                    </small>)
                    :
                    <><EmailInput submit={submit} email={email} setEmail={setEmail} className="mb-3" />
                        <Button onClick={submit} className="w-100">{loading && <Spinner size="sm" animation="border" className="me-1" />}Log In</Button></>
            }
        </div>
    </div>;
}

function EmailInput(props) {
    const { submit, email, setEmail, className } = props;

    return (
        <Form.Control
            type="email"
            placeholder="Enter your email"
            className={className}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            onKeyPress={(event) => {
                if (event.code === "Enter") {
                    submit();
                }
            }}
        />
    );
}