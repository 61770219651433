import { Logo } from "Common/Logo";
import { JobsDashboardPage } from "JobsDashboardPage";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import React, { useContext, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Route, useHistory, useParams, useRouteMatch } from "react-router-dom";
import Header from "./Common/Header";
import { UserContext } from "./Common/UserContext";
import logo from "./images/common/logo.png";

const JOB_LINK = "jobs";


function DashboardContent(props) {
    return (
        <div
            className="dashboard-content h-100 p-0 flex-grow-1"
            style={{ width: 0, position: "relative" }}
        >
            {props.children}
            <div className="panel-shadow"></div>
        </div>
    );
}

function Dashboard(props) {
    const { setSelectedItem } = props;
    const { itemId } = useParams();

    let dashboardContent;

    dashboardContent = <DashboardContent>
        <JobsDashboardPage jobId={itemId} setSelectedItem={(jobId) => setSelectedItem([JOB_LINK, jobId])} />
    </DashboardContent>;

    return (
        <>
            {dashboardContent}
        </>
    );
}

function DashboardPage() {
    const user = useContext(UserContext);
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const history = useHistory();
    const { url } = useRouteMatch();

    const setSelectedItem = (item) => {
        if (!item) {
            history.push(`${url}`);
        } else {
            history.push(url + `${item[0]}/${item[1]}`);
        }
    };

    const body = (
        <Dashboard
            setSelectedItem={setSelectedItem}
            user={user}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
        />
    );

    return (
        <>
            <Header hideHelp logo={<div className="align-items-center h-100 logo d-flex"><Logo className="h-100 me-3" />
                <span className="title" style={{ fontWeight: "400", position: "relative", bottom: "1px" }}>
                    Data
                </span>
</div>} className="d-none d-sm-flex" />
            <Header onClick={() => setSidebarCollapsed(!sidebarCollapsed)} className="d-flex d-sm-none" />
            <Container
                fluid
                className="dashboard bg-light d-flex flex-column flex-grow-1"
                style={{ background: "url(" + logo + ")", backgroundSize: "30px" }}
            >
                <Row className="d-flex flex-row h-100">
                    <Route path="/:itemType?/:itemId?/:subItem?">{body}</Route>
                </Row>
            </Container>
        </>
    );
}

export default DashboardPage;
