import React from "react";
import { Form } from "react-bootstrap";
import { BiTrash } from "react-icons/bi";
import { CgAdd } from "react-icons/cg";

export function KeyValueEditor(props) {
    const { data, save } = props;

    const [data_, setData_] = React.useState(data?.length === 0 ? [["", ""]] : data);

    const addRow = () => {
        setData_([...data_, ["", ""]]);
    }

    const setItem = (idx, key, value) => {
        const newData = [...data_];
        newData[idx] = [key, value];
        setData_(newData);
    }

    const removeItem = (idx) => {
        let newData = [...data_];
        newData.splice(idx, 1);
        setData_(newData).then(() => {
            save();
        });
    }

    return <div className="d-flex flex-column">
        {data_?.map(([key, value], idx) => {
            return <div key={idx} className="d-flex align-items-center justify-content-between mb-2">
                <Form.Control type="text" value={key} onChange={(event) => {
                    setItem(idx, event.target.value, value);
                }} onBlur={() => {
                    save(data_);
                }} />
                <div className="mx-3" style={{cursor: "default"}}>&rarr;</div>
                <Form.Control type="text" value={value} onChange={(event) => {
                    setItem(idx, key, event.target.value);
                }} onBlur={() => {
                    save(data_);
                }} />
                <BiTrash className="flex-shrink-0 pointer ms-2" style={{fontSize: "larger"}} onClick={() => {
                    removeItem(idx);
                }} />
            </div>
        })}
        <div className="d-flex mt-2 justify-content-end">
            <CgAdd className="pointer" onClick={addRow} style={{fontSize: "larger"}} />
        </div>
    </div>
}