import React, { createRef, useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

export function IconButton(props) {
  const {
    icon,
    label,
    onClick,
    className,
    tooltip,
    tooltipPlacement,
    style,
    size,
    fontWeight,
    variant,
    disabled,
    waitToEnable,
    spaceBetween,
    muted,
  } = props;

  let ref = createRef();

  const [timeoutPassed, setTimeoutPassed] = useState(false);

  useEffect(() => {
    if (waitToEnable) {
      window.setTimeout(() => {
        setTimeoutPassed(true);
      }, Math.min(Math.max(waitToEnable, 0), 5000))
    }
  }, [waitToEnable]);

  const _className = `d-flex align-items-center pointer ${className}`;
  const innerContent = (
    <div className="d-flex flex-row w-100" ref={ref}>
      <div
        className={`d-flex align-items-center pointer me-${
          label ? spaceBetween ?? 1 : 0
        }`}
        style={{ fontSize: "130%" }}
      >
        {icon}
        {label && " "}
      </div>
      {label && (
        <div
          className="pointer"
          style={{ marginLeft: "auto", marginRight: "auto", fontWeight: !muted && onClick && (fontWeight ?? "600") }}
        >
          {label}
        </div>
      )}
    </div>
  );

  let outerContent;

  if (onClick) {
    outerContent = (
      <Button
        className={_className}
        style={{ width: "fit-content", height: "fit-content", ...style }}
        size={size}
        variant={variant ?? "secondary"}
        onClick={onClick}
        disabled={disabled || (waitToEnable && !timeoutPassed)}
      >
        {innerContent}
      </Button>
    );
  } else {
    outerContent = (
      <div
        className={_className}
        style={{ cursor: "default", width: "fit-content", ...style }}
        size={size}
        variant={variant}
        onClick={onClick}
      >
        {innerContent}
      </div>
    );
  }

  if (tooltip) {
    outerContent = (
      <OverlayTrigger
        placement={tooltipPlacement ?? "right"}
        delay={{ show: 1000 }}
        overlay={(props) => (
          <Tooltip id="blockify-button-tooltip" {...props}>
            {tooltip}
          </Tooltip>
        )}
      >
        {outerContent}
      </OverlayTrigger>
    );
  }

  return outerContent;
}
