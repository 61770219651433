import React from "react";


export default function HR(props) {
  return <div
        className={`align-items-center d-flex flex-shrink-0 ${props.className}`}
        style={{ backgroundColor: "#f0f0f0", height: "0.8em", marginLeft: "-1em", marginRight: "-1em", ...props.style }}
      >
        <div
          className="ms-5 px-3"
          style={{
            fontVariant: "all-small-caps",
            marginBottom: "0.1em",
            background: "white",
            color: "#ccc",
            fontSize: props.size ?? "large",
            fontWeight: "600",
          }}
        >
          {props.label}
        </div>
      </div>
}