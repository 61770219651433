import { UserContext } from "Common/UserContext";
import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import React, { createContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import DashboardPage from "./DashboardPage";
import LandingPage from "./LandingPage";

export const DownloadContext = createContext({});

function App() {
  const [user, setUser] = useState("loading");
const [downloads, setDownloads] = useState();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user && user.uid) {
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .onSnapshot((results) => {
            let document = results.data() ?? {};
            let userDetails = { ...user };
            userDetails["uid"] = user.uid;
            if (document.email) {
              userDetails["email"] = user.email;
            }
            if (document.display_name) {
              userDetails["displayName"] = document.display_name;
            }
            if (document.photo_url) {
              userDetails["photoURL"] = document.photo_url;
            }
            userDetails["features"] = document.features;
            userDetails["company"] = document.company;
            userDetails["config"] = document.config ?? {};
            setUser(userDetails);
          });
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    firebase.database().ref("jobs").on("value", (snapshot) => {
      const items = snapshot.val();
      const downloads = {};
      for (const id of Object.keys(items ?? {})) {
        downloads[id] = {id: id, ...items[id]};
      }
      setDownloads(downloads);
    });
  }, []);

  return (
    <>
      <UserContext.Provider value={user}>
        <DownloadContext.Provider value={downloads}>
          <Router>
            <Route
              path="/"
              component={(props) =>
                user === "loading" ? (
                  <></>
                ) : user?.uid ? (
                  <>
                    <DashboardPage {...props} />
                  </>
                ) : (
                  <>
                    <LandingPage {...props} />
                  </>
                )
              }
            />
          </Router>
        </DownloadContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default App;
