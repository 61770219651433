import React, { useState } from "react";

export function ColumnLayout(props) {
  const { widths, classNames, before, content, hideNotSelected, showAll } = props;

  const [selectedColumn, setSelectedColumn] = useState(0);

  return (
    <div
      className={`overflow-auto w-100 h-100 pt-2 ${showAll ? "d-block" : "d-flex flex-column"}`}
      style={{ position: "absolute", left: 0, top: 0 }}
    >
      <div className="d-flex flex-shrink-0">
        {before}
      </div>
      <div
        className={`column-layout-container flex-grow-1 ${showAll ? "d-block" : "d-flex"}`}
        style={{ marginLeft: !hideNotSelected && `${selectedColumn * -100}vw`, height: 0, whiteSpace: showAll ? "normal" : "nowrap" }}
      >
        {content.map((x, idx) => {
          return <div
            key={`${idx}`}
            className={`${
              hideNotSelected && idx !== selectedColumn && "d-none"
            } column-layout-column flex-shrink-0 ${!widths[idx] && "flex-grow-1 mw-100"} ${classNames?.length > idx && classNames[idx]}`}
            style={{ width: widths[idx] }}
          >
            {React.cloneElement(x, { setSelectedColumn: setSelectedColumn })}
          </div>;
        })}
      </div>
    </div>
  );
}
