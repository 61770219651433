import { FirestoreTeamItem } from "./TeamItem";
import firebase from "firebase/compat/app";
import React, { useEffect, useState } from "react";
import { ListGroup } from "react-bootstrap";
import unknownUser from "../../images/unknown_user.png";

export function UserItem(props) {
  const [userInfo, setUserInfo] = useState();
  const [company, setCompany] = useState("Personal Account");

  useEffect(() => {
    if (props.id) {
      firebase
        .firestore()
        .collection("users")
        .doc(props.id)
        .onSnapshot((doc) => {
          const user = doc.data();
          setUserInfo(user);
          if (user?.company) {
            firebase
              .firestore()
              .collection("teams")
              .doc(user?.company)
              .onSnapshot((team) => {
                setCompany(team.data()?.name ?? "Personal Account");
              });
          }
        });
    } else {
      setUserInfo({
        display_name: props.displayName,
        email: props.email,
      });
    }
  }, [props.id]);

  let className =
    "d-flex flex-row align-items-center user-item justify-content-between";
  if (props.small) {
    className += " user-item-small";
  }
  if (props.compact) {
    className += " user-item-compact";
  }

  className += ` ${props.className}`;

  const sizeName = props.large ? 24 : props.small ? 14 : 18;
  const sizeCompany = props.large ? 18 : props.small ? 8 : 14;
  const sizePicture = props.large ? 100 : props.small ? 40 : 50;

  if (!userInfo) {
    return props.showPlaceholder ? (
      <div className="user-item">
        <div className="user-item-photo"></div>
      </div>
    ) : (
      <></>
    );
  }

  if (props.nameOnly) {
    return userInfo.display_name ?? userInfo.email;
  }

  let initials = null;
  try {
    initials = (userInfo?.display_name ?? userInfo?.email)?.split(" ").map(x => x[0].toUpperCase());
  } catch {
    console.log("Missing user initials");
  }

  const photo = (
    <div className={`user-item-photo d-flex align-items-center ${props.vertical ? "mb-3" : props.compact ? "" : "me-3"}`} style={{ position: "relative", width: `${sizePicture}px`, height: `${sizePicture}px`, cursor: "default" }} title={userInfo?.display_name ?? userInfo?.email}>
      {userInfo?.photo_url ? <img
        src={
          userInfo?.photo_url ??
          userInfo?.photoURL?.replace("s96-c", "s400-c") ??
          unknownUser
        }
        alt="User profile"
        className="profile"
      /> : initials ? <div className="d-flex justify-content-center align-items-center profile" style={{
        background: "linear-gradient(0deg, #888, #555)",
        color: "white",
        fontSize: "1.2em",
        overflow: "hidden",
      }}>{initials}</div> : <img
        src={
          userInfo?.photo_url ??
          userInfo?.photoURL?.replace("s96-c", "s400-c") ??
          unknownUser
        }
        alt="User profile"
        className="profile"
      />}
      {userInfo?.company && !props.noCompany && (
        <div style={{ position: "absolute", right: `-${sizePicture / 8}px`, bottom: `-${sizePicture / 8}px` }}>
          <FirestoreTeamItem teamId={userInfo?.company} iconOnly />
        </div>
      )}
    </div>
  );

  const body = (
    <div className={`d-flex ${props.vertical ? "flex-column" : "flex-row"} align-items-center`}>
      {photo}
      <div className={`d-flex flex-column justify-content-center ${props.vertical ? "align-items-center" : "align-items-start"}`}>
        <div style={{ fontWeight: "600", fontSize: `${sizeName ?? 14}px`, marginBottom: "-0.25em" }}>
          {userInfo?.display_name && userInfo?.display_name != "" ? userInfo.display_name : userInfo?.email}
        </div>
        <div style={{ textTransform: "lowercase", fontVariant: "small-caps", fontSize: `${sizeCompany}px`, color: props.dark ? "#888" : "#555" }}>{company}</div>
      </div>
    </div>
  );

  return props.compact ? (
    <div className={className} style={{backgroundColor: props.dark && "transparent"}}>
      <div className="d-flex flex-column justify-content-center">{photo}</div>
    </div>
  ) : props.noBorder ? (
    <div className={className}>{body}</div>
  ) : (
    <ListGroup.Item style={{backgroundColor: props.dark && "transparent", border: (props.dark || props.noBorder) && "0", color: props.dark && "white"}} className={className}>
      {body}
      {props.children}
    </ListGroup.Item>
  );
}
