import React from "react";
import moment from "moment";


export function DateTime(props) {
    const { epochSeconds, className } = props;

    return <span className={className}>{new Date(epochSeconds * 1000).toLocaleString()}</span>;
}

export function RelativeDateTime(props) {
    const { epochSeconds, className } = props;

    const date = new Date(epochSeconds * 1000);

    return <span className={className}>{moment(date).fromNow()}</span>;
}
