import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { IoIosHelpBuoy } from "react-icons/io";
import { useHistory } from "react-router-dom";
import { IconButton } from "./IconButton";
import { Logo } from "./Logo";
import { UserContext } from "./UserContext";
import { UserItem } from "./UserItem";

function Header(props) {
  const { hideUser, hideHelp, onClick, className, path, logo } = props;

  const user = useContext(UserContext);

  const history = useHistory();

  return (
    <>
      <header className={`Header navbar navbar-dark bg-dark ${className}`} style={{ position: "sticky" }}>
        <div className="header-item">
          {logo || <Logo onClick={onClick} />}
          {path && path.map((x, idx) => {
            return <div key={idx} className="d-flex align-items-center" style={{position: "relative", top: "0.15em"}}>
              <FaAngleRight className="mx-3 text-muted" />
              <Button variant="link" onClick={() => {
                if (x[1]) {
                  history.push(x[1]);
                }
              }} className="text-muted" style={{position: "relative", bottom: "0.05em", fontSize: "x-large", fontFamily: "Oswald"}}>{x[0]}</Button>
            </div>
          })}
        </div>
        <div className="d-flex align-items-center h-100">
          {!hideHelp && (
            <div className="d-none d-md-flex">
              <IconButton
                icon={<IoIosHelpBuoy />}
                label="Show Help"
                variant="warning"
                size="sm"
                className="me-3"
                onClick={() => {
                    firebase.firestore().collection("settings").doc(user.uid).update({
                      "help.visible": true
                    });
                  }
                }
              />
            </div>
          )}
          <div className="header-menu header-item d-flex flex-row align-items-center">
            {!hideUser && user ? (
              <>
                <div className="d-none d-lg-flex pointer"><UserItem id={user?.uid} dark /></div>
                <div className="d-flex d-lg-none pointer"><UserItem id={user?.uid} dark compact /></div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
